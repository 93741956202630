import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getOrderSignings (orderId) {return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/${orderId}`);},
    saveDeleteRecords (items, deletedItems) { return HttpWrapper.post(`${endpoints.ORDER_SIGNING}`, {items, deletedItems}); },
    getAll (searchRequest) { return HttpWrapper.post(`${endpoints.ORDER_SIGNING}/getAll`, searchRequest); },
    savePavasoOrder(ordersID) { return HttpWrapper.post(`${endpoints.ORDER_SIGNING}/pavaso`, { ordersID }); },
    getPavasoDeepLink(orderSigningID) { return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/pavasoDeepLink/${orderSigningID}`);},
    getSigningSessionInProgress(orderGUID) { return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/signingSessionInProgress/${orderGUID}`);},
    getPavasoTaggingToken() { return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/pavasoTaggingToken`)},
    updateOrderSigningDocument(document) { return HttpWrapper.post(`${endpoints.ORDER_SIGNING}/orderSigningDocument`, document); },
    getPavasoPartyRoleIdentifier(ordersId) { return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/pavasoPartyRoleIdentifier/${ordersId}`); },
    getPavasoOrderIsReady(ordersID) { return HttpWrapper.get(`${endpoints.ORDER_SIGNING}/pavasoOrderIsReady/${ordersID}`);},
}